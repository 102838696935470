import React from "react";
import "../styles.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logoty"></div>
      <div className="menu">
        <a href="/">Create</a>
        <a href="login">Exit</a>
      </div>
    </nav>
  );
};

export default Navbar;
