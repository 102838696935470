import React, { useState } from "react";
import axios from "axios";
import "../styles.css";
import BASE_URL from "../config"; // URL сервера

const PromptInput = () => {
    const [prompt, setPrompt] = useState("");
    const [age, setAge] = useState("3");
    const [voice, setVoice] = useState("Alloy");
    const [style, setStyle] = useState("3D");
    const [isLoading, setIsLoading] = useState(false); // Стан завантаження
    const [showLimitModal, setShowLimitModal] = useState(false); // Для модального вікна

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!prompt.trim()) {
            console.error("Enter a prompt!");
            return;
        }

        setIsLoading(true); // Початок завантаження
        const token = localStorage.getItem("token"); // Отримуємо токен з LocalStorage

        try {
            const response = await axios.post(`${BASE_URL}/api/save-prompt/`, {
                text: prompt, // Текст промпта
                tape: "default", // Тип (default або History)
                age: age, // Вік
                style_img: style, // Стиль зображення
                voice_type: voice, // Голос
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, // Авторизація
                },
            });

            if (response.status === 201) {
                console.log("Prompt successfully saved!");
                setPrompt(""); // Очищення після успіху
                window.location.reload()
            }
        } catch (error) {
            if (error.response) {
                console.error("❌ Error Response:", error.response.status, error.response.data);

                // Якщо 403 - відкриваємо модальне вікно
                if (error.response.status === 403) {
                    setShowLimitModal(true);
                }
            } else {
                console.error("❌ Network or Unknown Error:", error.message);
            }
        } finally {
            setIsLoading(false); // Завершення завантаження
        }
    };

    return (
        <div className="prompt-container">
            {/* Поле вводу + кнопка відправки */}
            <div className="prompt-input-wrapper">
                <input
                    type="text"
                    placeholder="Enter your prompt..."
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                />
                <button className="send-button" onClick={handleSubmit} disabled={isLoading}>
                    {isLoading ? (
                        "⏳"
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-wand-sparkles"
                        >
                            <path d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72"></path>
                            <path d="m14 7 3 3"></path>
                            <path d="M5 6v4"></path>
                            <path d="M19 14v4"></path>
                            <path d="M10 2v2"></path>
                            <path d="M7 8H3"></path>
                            <path d="M21 16h-4"></path>
                            <path d="M11 3H9"></path>
                        </svg>
                    )}
                </button>
            </div>

            {/* Мініатюрні випадаючі списки */}
            <div className="mini-dropdown-container">
                <div className="mini-dropdown">
                    <button className="mini-dropdown-button">{age}</button>
                    <div className="mini-dropdown-content">
                        {[
                            {label: "3-5", value: "4"},
                            {label: "5-7", value: "6"},
                            {label: "7-12", value: "9"},
                            {label: "12-16", value: "14"}
                        ].map((item) => (
                            <p key={item.label} className={age === item.value ? "selected" : ""}
                               onClick={() => setAge(item.value)}>
                                {item.label}
                            </p>
                        ))}
                    </div>
                </div>

                <div className="mini-dropdown">
                    <button className="mini-dropdown-button">{voice}</button>
                    <div className="mini-dropdown-content">
                        {["Alloy", "Echo", "Fable", "Onyx", "Shimmer"].map((item) => (
                            <p key={item} className={voice === item ? "selected" : ""} onClick={() => setVoice(item)}>
                                {item}
                            </p>
                        ))}
                    </div>
                </div>

                <div className="mini-dropdown">
                    <button className="mini-dropdown-button">{style}</button>
                    <div className="mini-dropdown-content">
                        {["3D", "Cartoon", "Real"].map((item) => (
                            <p key={item} className={style === item ? "selected" : ""} onClick={() => setStyle(item)}>
                                {item}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
{showLimitModal && (
    <div className="modal_limit">
        <div className="modal-content_limit">
            {/* Хрестик для закриття модального вікна */}
            <button className="modal-close_limit" onClick={() => setShowLimitModal(false)}>✖</button>

            <h2>Video Limit Reached</h2>
            <p>Thank you for your interest in Mahaon! Our app is currently in beta, and video generation is limited to one request.</p>
            <p>If you’d like to get full access or learn more, leave your contact, and we’ll be in touch with you soon!</p>
            <p>Together, we’ll make Mahaon even better!</p>
            <button className="close-button_limit" onClick={() => setShowLimitModal(false)}>OK</button>
        </div>
    </div>
)}
        </div>

    );
};

export default PromptInput;
