import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation  } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext'; // Імпорт AuthProvider
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Start from './Start';
import { GoogleOAuthProvider } from "@react-oauth/google"
import { useEffect } from 'react';

const RedirectToHome = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/home') {
      window.location.replace('/');
    }
  }, [location.pathname]);
  return null;
};

const App = () => {
  useEffect(() => {
    const currentHost = window.location.hostname;
    const protocol = window.location.protocol;

    if (protocol !== 'https:') {
      // window.location.href = `https://${currentHost}${window.location.pathname}${window.location.search}`;
    }
    if (
      currentHost !== 'localhost' &&
      currentHost !== '127.0.0.1' &&
      currentHost !== 'mahaonai.com'
    ) {
      const currentPath = window.location.pathname + window.location.search;
      window.location.href = `https://mahaonai.com${currentPath}`;
    }
  }, []);
  return (
    <AuthProvider> {/* Обгортаємо додаток у AuthProvider */}
      <GoogleOAuthProvider clientId='372972581140-1s084rmms362e0p58b4k3miusfgnt4rm.apps.googleusercontent.com'>
      <Router>
        <RedirectToHome />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Start />} />
        </Routes>
      </Router>
      </GoogleOAuthProvider>
    </AuthProvider>
  );
};

export default App;
