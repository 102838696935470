import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import PromptInput from "./components/PromptInput";
import VideoList from "./components/VideoList";
import axios from "axios";
import BASE_URL from "./config"; // Використовуємо змінну для API
import "./styles.css";
import { useNavigate } from "react-router-dom"; // Додаємо useNavigate для редіректу
import { Progress } from 'antd';
// import 'antd/dist/antd.css'; // Імпортуйте стилі Ant Design
import 'antd/dist/reset.css';

const Start = () => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // Використовуємо useNavigate для навігації

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login"); // Редірект на сторінку логіну, якщо немає токена
      return;
    }
    const fetchVideos = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${BASE_URL}/api/user/prompts`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response);
        const fetchedVideos = response.data.map((video) => ({
          id: video.id,
          title: video.title || "Untitled",
          videoUrl: video.video || null,
          videoText: video.text || "No description",
          thumbnail: video.thumbnail_url || null,
          views: video.views || "0",
          time_ago: video.time_ago || "Just now",
          status: video.status || "pending",
          progress: video.progress || "0",
        }));

        setVideos(fetchedVideos);
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="app">
      <div className="background-blobs"></div>
      <Navbar />
      <div className="content">
        <PromptInput />
        {isLoading ? <p>Loading videos...</p> : <VideoList videos={videos} />}
      </div>
    </div>
  );
};

export default Start;
